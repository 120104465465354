import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const NotFound = () => {
	return (
		<Container className="px-0" fluid>
			<Row className="py-5 bgColorDefault2">
				<Col className="col-12 p-5 text-center textShadowDefault2">
					<h1 className="fw-bold">GREŠKA 404<br />STRANICA NIJE PRONAĐENA</h1>
				</Col>
				<Col className="py-3 py-xl-5 text-center">
            <Button
              href="/"
              className="bgColorDefault border-rounded fw-bold fs-3 py-3 px-5 text-dark btnclass textShadowDefault boxShadowBlack"
            >POVRATAK NA NASLOVNU
            </Button>
          </Col>
			</Row>
			
		</Container>
	);
}
export default NotFound;