import React, { useState } from "react";
import { Col, Container, Row, Nav, Navbar, Offcanvas } from "react-bootstrap";
import Contact from "Contact";
import { FaFacebookSquare } from "react-icons/fa";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { HiOutlineMail } from "react-icons/hi";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showContact, setShowContact] = useState(false);

  return (
    <Container className="bgColorDefault mx-0 px-0 pt-2 navbarSticky" fluid>
      <Row className="mx-0 py-0 d-flex justify-content-between position-relative border-top border-bottom border-5 border-dark boxShadowBlackSection header">
        <Col className="col-5 ms-3 me-auto align-content-center my-auto z-1">
          <Navbar expand={'xxl xl lg md sm'} className="d-xl-none d-block" style={{ color: "red" }}>
            <Navbar.Toggle onClick={handleShow} className="heroStyle bgColorDefault2 rounded" style={{ border: "3px dashed black" }} />
            <Navbar.Offcanvas show={show} placement="top" className="h-0 p-0 m-0 w-100 col-12" style={{ transition: "none", background: "#00000000" }}>
              <Container className="col-12 h-auto w-100 p-2 bgColorDefault" style={{ border: "5px dashed black" }} fluid>
                <Offcanvas.Header className="position-relative d-flex p-3">
                  <Offcanvas.Title className='col-12 d-flex justify-content-center align-items-center py-2'>
                    <h2 className="fs-1 fw-bold my-0 py-0 textShadowDefault">MENI</h2>
                  </Offcanvas.Title>
                  <Row className='position-absolute z-1 h1 fw-bold py-1 m-3 fs-2 fw-bold bgColorDefault2 boxShadowBlack'
                    style={{ right: 0, top: 0, border: "3px dashed", borderRadius: "10px", color: "black", textShadow: "1px 1px #000000", padding: "13px" }}
                    onClick={handleClose}
                  >X
                  </Row>
                </Offcanvas.Header>
                <hr style={{ border: "2px dashed black", opacity: 1 }} />
                <Offcanvas.Body className='p-1'>
                  <Container fluid>
                    <Row className="py-2 text-center">
                      <Nav.Link href={"/#usluge"} onClick={handleClose}><h3 className="navLinks pb-4">USLUGE</h3></Nav.Link>
                      <Nav.Link href={"/#galerija"} onClick={handleClose}><h3 className="navLinks pb-4">GALERIJA</h3></Nav.Link>
                      <Nav.Link onClick={() => { setShowContact(true) }}><h3 className="navLinks">KONTAKT</h3></Nav.Link>
                    </Row>
                  </Container>
                  <hr style={{ border: "2px dashed black", opacity: 1 }} />
                  <Container className="px-0 mx-0" fluid>
                    <Row className="col-auto col-md-10 col-lg-8 mx-auto py-3 text-center">
                      <Nav.Link className="col-3 my-auto" href="tel:00385-97-609-06-22" ><FaPhoneSquareAlt size={46} className="my-auto" style={{ color: "black" }} /></Nav.Link>
                      <Nav.Link className="col-3 my-auto" href="whatsapp://send?phone=+385976090622"><FaSquareWhatsapp size={46} className="my-auto" style={{ color: "black" }} /></Nav.Link>
                      <Nav.Link className="col-3 my-auto" href="https://www.facebook.com/sargas.autoplus"><FaFacebookSquare size={46} className="my-auto" style={{ color: "black" }} /></Nav.Link>
                      <Nav.Link className="col-3 my-auto" href="mailto: asplus2023@gmail.com"><HiOutlineMail size={54} className="my-auto" style={{ color: "black" }} /></Nav.Link>
                    </Row>
                  </Container>
                </Offcanvas.Body>
              </Container>
            </Navbar.Offcanvas>
          </Navbar>
          <Row className="col-auto p-1 d-xl-flex d-none">
            <Nav className="p-0 m-0">
              <Nav.Link className='my-auto' href={"/#usluge"}><h3 className="p-0 m-0 navLinks textShadowDefault">USLUGE</h3></Nav.Link>
              <Nav.Link className='my-auto' href={"/#galerija"}><h3 className="p-0 m-0 navLinks textShadowDefault">GALERIJA</h3></Nav.Link>
              <Nav.Link className='my-auto' onClick={() => { setShowContact(true) }}><h3 className="p-0 m-0 navLinks textShadowDefault">KONTAKT</h3></Nav.Link>
            </Nav>
          </Row>
        </Col>
        <Col className="mx-0 position-absolute h-100 py-0 my-0 top-0 z-0">
          <Col
            className="mx-auto col-3 col-md-3 col-xl-2 align-self-center bg-dark h-100 py-0 my-0"
            style={{ clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)" }}
          ></Col>
        </Col>
        <Col className="col-5 mx-0 align-content-center my-auto px-0 text-end py-0 my-0 z-1">
          <Col className="">
            <Nav.Link className='pt-1 my-auto text-center textShadowDefault' href={"/"}>
              <h2 className="fw-bold fs-1 align-middle">SARGAS</h2>
            </Nav.Link>
          </Col>
        </Col>
      </Row>
      <Contact
        showContact={showContact}
        setShowContact={setShowContact}
      />
    </Container>
  );
}
export default Header;