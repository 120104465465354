import { Col, Row, Button, Modal, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

const Contact = ({showContact, setShowContact}) => {
  const hideContact = () => {
    setShowContact(false);
  };

  return (
    <Modal
      show={showContact}
      onHide={() => hideContact()}
      backdrop="static"
      keyboard={false}
      animation={false}
      centered
      >
        <Container className='px-1 bgColorDefault rounded' style={{border: "7px dashed black"}}  fluid>
          <Row className='position-absolute z-1 h1 fw-bold py-1 m-4 fs-2 fw-bold bgColorDefault2 boxShadowBlack'
            style={{right: 0, top: 0, border: "3px dashed", borderRadius: "10px", color: "black", textShadow: "1px 1px #000000", padding: "13px"}}
            onClick={hideContact}
            >X
          </Row>
          <Row>
            <Col className='col-12 py-4'>
              <h2 className="fs-1 fw-bold textShadowDefault text-center">KONTAKT</h2>
            </Col>
          </Row>
          <hr className='my-0' style={{border: "2px dashed black", opacity: 1}}/>
          <Row>
            <Col className="col-12 fw-bold fs-3 text-center pt-4">ANTONIA UJVARI</Col>
            <Col className="col-12 fw-bold fs-3 text-center">Telefon: 097 609 06 22</Col>
            <Col className="col-12 fw-bold fs-4 text-center pb-4 text-break d-inline d-sm-none">Email: asplus2023@gmail.com</Col>
            <Col className="col-12 fw-bold fs-3 text-center pb-4 text-break d-none d-sm-inline">Email: asplus2023@gmail.com</Col>
          </Row>
          <hr className='my-0' style={{border: "2px dashed black", opacity: 1}}/>
          <Row className="py-5 text-center">
            <Col>
              <Button
                href="tel:00385-97-609-06-22"
                className="bgColorDefault2 border-rounded fw-bold fs-3 py-3 px-5 text-dark btnclass textShadowDefault boxShadowBlack"
              >NAZOVI ODMAH!
              </Button>
            </Col>
          </Row>
        </Container>
        
        
    </Modal>
  )
}
export default Contact
