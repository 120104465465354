import { Col, Row, Button, Image, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

const Gallery = ({ imagesRef, showGallery, setShowGallery, showImageByID, setSelectedImageID }) => {
  const [images, setImages] = useState([]);
  const imagesThumbnailsRefs = useRef({});
  const [imageIDs, setImageIDs] = useState([]);
  const setImageID = (imageID) => {
    setSelectedImageID(imageID);
    imagesThumbnailsRefs.current['imageThumbnail_' + imageID].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  };

  const hideGallery = () => {
    setShowGallery(false);
  };

  //TRIGGER - ON CREATE
  useEffect(() => {
    if (imagesRef) {
      setImages(imagesRef);
      setImageIDs(imagesRef.map(img => img));
    }
  }, [imagesRef]);

  //FUNCTION TO SELECT NEXT / PREV IMAGE
  const selectNextPrevImage = (isNext) => {
    const currentIndex = imageIDs.indexOf(showImageByID);
    const nextIndex = (isNext ? (currentIndex + 1) : (currentIndex - 1 + imageIDs.length)) % imageIDs.length;
    setImageID(imageIDs[nextIndex]);
  };

  return (
    <Modal
      show={showGallery}
      onHide={() => hideGallery()}
      backdrop="static"
      keyboard={false}
      fullscreen={true}
      animation={false}
      centered
    >
      <Modal.Body className='px-4 py-0 position-relative bgColorDefault2' >
        <Row className='position-absolute z-1 h1 fw-bold py-1 px-3 m-4 m-lg-5 fs-1 fw-bold bgColorDefault boxShadowBlack'
          style={{ right: 0, top: 0, border: "5px dashed", borderRadius: "15px", color: "black", textShadow: "1px 1px #000000" }}
          onClick={() => hideGallery()}
        >X
        </Row>

        <Row className='h-100 z-0'>
          <Col className='col-3 col-lg-2 position-absolute top-50 start-0 translate-middle-y h-100'>
            <Button
              className='w-100 h-100 fw-bold'
              style={{fontSize: "100px", color: "#000000", backgroundColor: "transparent", background: "transparent", border: "none"}}
              onClick={() => { selectNextPrevImage(false) }}>
              {"<"}
            </Button>
          </Col>
          <Col id='col_galleryImages' className='col-0 d-flex h-100 justify-content-center align-items-center px-0 mx-0'>
            {
              images && images.length > 0 ?
                images.filter((ele) => ele === showImageByID).map((ele, index) => (
                  <Row key={'g_i_' + ele} className={"col-12 h-100 d-flex px-0 mx-0"}>
                    <Image src={ele} alt={"Slika iz galerije " + index} className='object-fit-contain w-100 h-100 px-0 mx-0 ' />
                  </Row>
                ))
                : <></>
            }
          </Col>
          <Col className='col-3 col-lg-2 position-absolute top-50 end-0 translate-middle-y me-2 h-100'>
            <Button
              className='w-100 h-100 fw-bold'
              style={{fontSize: "100px", color: "#000000", backgroundColor: "transparent", background: "transparent", border: "none"}}
              onClick={() => { selectNextPrevImage(true) }}>
              {">"}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className='px-0 mx-0 justify-content-center bgColorDefault'>
        <Row className='d-flex w-100 px-2 px-sm-3 px-md-4'>
          <Col className="col-auto mx-auto d-flex flex-nowrap overflow-x-scroll hideScrollbar">
            {
              images && images.length > 0 ?
                images.map((ele, index) => (
                  <Col className={'mx-auto px-2'} key={'g_t_' + index}>
                    <Image
                      ref={(ref) => (imagesThumbnailsRefs.current['imageThumbnail_' + ele] = ref)}
                      src={ele}
                      alt={"Thumbnail slika iz galerije " + index}
                      height={"80px"}
                      width={"100px"}
                      className={'rounded object-fit-cover ' + (showImageByID === ele ? 'selectedThumbnail' : '')}
                      onClick={() => setImageID(ele)} />
                  </Col>
                ))
                : <></>
            }
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}
export default Gallery
