import CardUsluge from "CardUsluge";
import React, { useState } from "react";
import { Col, Container, Row, Image, Button, Placeholder, Card } from "react-bootstrap";
import Gallery from "Gallery";
import towtruckIcon from "../src/ikone/towtruckIcon.svg";
import delivery from "../src/ikone/delivery.svg";
import icon_24h from "../src/ikone/24Icon.svg";
import carIcon from "../src/ikone/carIcon.svg";
import carRepairIcon from "../src/ikone/carRepairIcon.svg";
import carService from "../src/ikone/carService.svg";
import mapLocation from "../src/ikone/mapa.jpg";
import mapLocation_webp from "../src/ikone/mapa.webp";
import podrucjeDjelovanja from "../src/ikone/podrucjeDjelovanja.jpg";
import podrucjeDjelovanja_webp from "../src/ikone/podrucjeDjelovanja.webp";
import Logo from "../src/logo.jpg";
import Logo_webp from "../src/logo.webp";

const Content = () => {
  const [showGallery, setShowGallery] = useState(false);
  const [selectedImageID, setSelectedImageID] = useState(0);

  const images = require.context('../public/slike', false);
  const imagesList = images.keys().map(image => images(image));

  //const images_webp = require.context('../public/slike/webp', false);
  //const imagesList_webp = images_webp.keys().map(image => images_webp(image));
  //console.log(imagesList_webp);

  return (
    <Container className="px-0" fluid>
      <Row className="mx-0 pb-4 bgColorDefault2 d-flex">
        <Row className="col-12 mx-auto px-2 my-5 d-flex align-items-center">
          <Row className="px-3 mx-0 py-md-3 rounded heroStyle align-items-center">
            <Col className="col-12 mx-auto col-md-5 col-lg-6 px-0 mx-0 px-lg-5 pt-3 pt-md-0">
              {
                Logo ? 
                <picture>
                  <source srcSet={Logo_webp} type="image/webp" />
                  <Image src={Logo} alt="Sargas logo" className="w-100 object-fit-contain d-flex d-lg-none" height={"140px"}/>
                  <Image src={Logo} alt="Sargas logo" className="w-100 object-fit-contain d-none d-lg-flex" height={"180px"}/>
                </picture>
                :
                <Placeholder as={Card.Img} height={"250px"} className="w-100 rounded" />
              }
            </Col>
            <Col className="col-12 col-md-7 col-lg-6 mx-0 px-1 px-md-2 px-lg-3 py-3 py-md-5">
              <h1 className="fw-bold text-center" >
                <span className="fs-1" style={{color: "#ff0000"}}>VUČNA SLUŽBA SARGAS</span>
                <br />
                <span className="fs-2" style={{color: "white"}}>SARGAS AUTO PLUS d.o.o.</span>
              </h1>
            </Col>
          </Row>
        </Row>
        <Row className="col-12 mx-auto px-4 pb-5 pt-0 align-items-center justify-content-center">
          <Col className="col-12 col-xl-6 px-2 px-sm-4 px-md-5 pb-4 pb-xl-0 order-xl-2">
            <p className="col-12 fw-bold fs-5 mx-auto justify-self-center text-center">Nudimo usluge šlepanja ili vuče raznih vozila, prijevoz težeg tereta, dijagnoza kvara vozila. Dostupni smo 0-24, 7 dana u tjednu. Djelujemo po čitavoj Hrvatskoj, te okolnim državama.
            </p>
          </Col>
          <Col className="col-12 col-xl-6 order-xl-1">
            <Col className="h1 fw-bold pb-2 text-center pb-xl-2 pt-xl-4 textShadowDefault2">KONTAKTIRAJTE NAS</Col>
            <Col className="fw-bold text-center fs-3">Kontakt telefon</Col>
            <Col className="fw-bold text-center fs-3">097 609 06 22</Col>
            <Col className="pt-4 text-center">
              <Button
                href="tel:00385-97-609-06-22"
                className="bgColorDefault border-rounded fw-bold fs-3 py-3 px-5 text-dark btnclass textShadowDefault boxShadowBlack"
              >NAZOVI ODMAH!
              </Button>
            </Col>
          </Col>
        </Row>
      </Row>
      <Row className="mx-0 my-0 py-0 d-flex justify-content-center position-relative border-top border-bottom border-5 border-dark boxShadowBlackSection" style={{ height: "140px" }}>
        <Col className="col-12 h-100 m-0 bgColorDefault" style={{ minHeight: "100%" }}></Col>
        <Col
          className="col-4 col-md-3 col-xl-3 position-absolute align-self-center bg-dark h-100"
          style={{ clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)" }}
        ></Col>
        <Row className="position-absolute d-flex justify-content-center align-content-center text-center h-100">
          <Col className="col-12 py-0 my-0">
            <h2 className="fw-bold fs-1 py-0 my-0" style={{ color: "#babfc4", textShadow: "2px 2px #FFFFFF", lineHeight: "1.5"}}>DOSTUPNI SMO 0-24<br />7 DANA U TJEDNU</h2>
          </Col>
        </Row>
      </Row>
      <Row id="usluge" className="mx-0 py-5 px-3 px-lg-5 bgColorDefault2">
        <Col className="col-12 text-center pb-5 textShadowDefault2">
          <h2 className="fw-bold fs-1">USLUGE</h2>
        </Col>
        <CardUsluge icon={towtruckIcon} text="Šlepanje ili vuča raznih vrsta vozila, motocikala, te manjih teretnih vozila" />
        <CardUsluge icon={delivery} text="Prijevoz kamp prikolica, bijele tehnike, radnih strojeva, te ostali teži teret" />
        <CardUsluge icon={carService} text="Zamjena guma, dostava goriva, paljenje vozila busterom, te ukoliko je moguće osposobljavanje za daljnji put" />
        <CardUsluge icon={carRepairIcon} text="Dijagnoza kvara fizička i kompjuterska" />
        <CardUsluge icon={carIcon} text="Mogućnost zamjenskog vozila" />
        <CardUsluge icon={icon_24h} text="Vučna služba 0-24, 7 dana u tjednu" />
      </Row>
      <Row className="mx-0 px-4 py-5 justify-content-center border-top border-bottom border-5 border-dark bgColorDefault boxShadowBlackSection">
        <Row className="mx-0 col-12 pb-5 text-center justify-content-center textShadowDefault">
          <h2 className="fw-bold fs-1">PODRUČJE DJELOVANJA</h2>
        </Row>
        <Row className="col-12 col-xl-auto mx-0 mx-xl-auto px-0 pb-5">
          <Col className="col-12 col-md-auto mx-auto align-content-center">
            {
              mapLocation ?
                <picture>
                  <source srcSet={podrucjeDjelovanja_webp} type="image/webp" />
                  <Image
                    src={podrucjeDjelovanja}
                    height={"350px"}
                    alt={"Podrucje djelovanja"} 
                    className='rounded object-fit-cover w-100 d-flex d-md-none selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                  <Image
                    src={podrucjeDjelovanja}
                    height={"470px"}
                    alt={"Podrucje djelovanja"} 
                    className='rounded object-fit-contain w-100 d-none d-md-flex selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                </picture>
                :
                <Placeholder as={Card.Img} height={"400px"} className='w-100 mx-0 px-0 rounded' />
            }
          </Col>
        </Row>
        <Row className="col-12 col-xl-4 mx-0 mx-xl-auto px-0 pb-5">
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇪🇺</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 my-auto text-start fs-2 fw-bold align-content-center">EUROPSKA UNIJA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇭🇷</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 my-auto text-start fs-2 fw-bold align-content-center">HRVATSKA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇮🇹</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 my-auto text-start fs-2 fw-bold align-content-center">ITALIJA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇸🇮</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 my-auto text-start fs-2 fw-bold align-content-center">SLOVENIJA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇧🇦</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 my-auto text-start fs-2 fw-bold align-content-center">BOSNA I HERCEGOVINA</Col>
          </Row>
          <Row className="mx-0 px-0 ps-xl-3 py-1 d-flex justify-content-center">
            <Col className="col-5 col-xl-3 text-end align-content-center flagIcon">🇷🇸</Col>
            <Col className="col-7 col-xl-9 ps-2 ps-xl-4 my-auto text-start fs-2 fw-bold align-content-center">SRBIJA</Col>
          </Row>
        </Row>
      </Row>
      <Row className="mx-0 my-0 py-0 d-flex justify-content-center z-2 position-relative border-top border-bottom border-5 border-dark boxShadowBlackSection" style={{ height: "140px" }}>
        <Col className="col-12 h-100 m-0 bgColorDefault2" style={{ minHeight: "100%" }}></Col>
        <Col
          className="col-4 col-md-3 col-xl-3 position-absolute align-self-center bg-dark h-100"
          style={{ clipPath: "polygon(25% 0, 100% 0%, 75% 100%, 0% 100%)" }}
        ></Col>
        <Row className="fw-bold fs-1 position-absolute d-flex justify-content-center align-content-center text-center h-100"
          style={{textShadow: "2px 2px #FFFFFF" }}
        >
        </Row>
      </Row>
      <Row id='pozicija' className="mx-0 px-4 py-5 position-relative z-1 border-top border-bottom border-5 border-dark bgColorDefault boxShadowBlackSection">
        <Row className="mx-0 col-12 pb-5 justify-content-center text-center textShadowDefault">
          <h2 className="fw-bold fs-1">GDJE SE NALAZIMO?</h2>
        </Row>
        <Row className="mx-0">
          <Col className="col-12 col-xl-auto mx-auto">
            {
              mapLocation ?
                <picture>
                  <source srcSet={mapLocation_webp} type="image/webp" />
                  <Image
                    src={mapLocation}
                    alt={"Google maps lokacija"}
                    height={"450px"}
                    className='rounded object-fit-cover w-100 d-flex d-xl-none selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                  <Image
                    src={mapLocation}
                    alt={"Google maps lokacija"}
                    height={"600px"}
                    className='rounded object-fit-contain w-100 d-none d-xl-flex selectedThumbnail textShadowDefault boxShadowBlack'
                  />
                </picture>
                :
                <Placeholder as={Card.Img} height={"400px"} className='w-100 mx-0 px-0 rounded' />
            }
          </Col>
          <Col className="col-12 mt-5 text-center">
            <Col className="h3 fw-bold">Adresa: Vladimira Gortana 6</Col>
            <Col className="h3 fw-bold">Mjesto: 52465, TAR</Col>
          </Col>
        </Row>
        <Row className="mx-0 py-5 justify-content-center">
          <Button
            href="https://www.google.com/maps/place/Rent+A+Car+PLUS/@45.2975809,13.6276775,474m/data=!3m1!1e3!4m6!3m5!1s0x477c9d4d9918614d:0x5f04593da2aae5a1!8m2!3d45.297672!4d13.6276293!16s%2Fg%2F11qn9zyd99?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D"
            target="_blank" rel="noreferrer noopener"
            className="border-rounded fw-bold fs-3 py-3 mt-0 mt-xl-4 px-5 text-dark w-auto btnclass textShadowDefault boxShadowBlack bgColorDefault2"
            >PRIKAŽI NA GOOGLE MAPS</Button>
        </Row>
      </Row>
      <Row id="galerija" className="mx-0 px-4 px-md-2 py-5 bgColorDefault2">
        <Col className="col-auto mx-auto pb-4 textShadowDefault2">
          <h2 className="fw-bold fs-1">GALERIJA</h2>
        </Col>
        <Row className='col-12 mx-0 px-0'>
          {
            imagesList && imagesList.length > 0 ?
              imagesList.filter((ele, index) => index < 6).map((ele, index) => (
                <Col key={"i" + index} className='col-12 col-md-6 col-xl-4 py-3 px-0 px-md-3'>
                  <Col>
                    <Image
                      src={ele}
                      alt={"Slika iz galerije "+index} 
                      height={"425px"}
                      className='rounded object-fit-cover w-100'
                      style={{ boxShadow: "0px 0px 10px 3px #ff0000" }}
                      onClick={() => {
                        setSelectedImageID(ele);
                        setShowGallery(true);
                      }}
                    />
                  </Col>
                </Col>
              ))
              :
              Array.from({ length: 9 }, (ele, index) => (
                <Col key={"i_p_" + index} className='col-12 col-md-6 col-xl-4 py-4 px-0 px-md-4'>
                  <Row className="mx-0">
                    <Placeholder as={Card.Img} height={"200px"} className='w-100 mx-0 px-0 rounded' />
                  </Row>
                </Col>
              ))
          }
        </Row>
        <Row className='col-auto mx-auto px-0 pt-5 pb-3'>
          <Button
            className="bgColorDefault border-rounded fw-bold fs-3 py-3 px-5 text-dark btnclass textShadowDefault boxShadowBlack"
            onClick={() => {
              setSelectedImageID(imagesList[0]);
              setShowGallery(true);
            }}
          >PRIKAŽI SVE SLIKE
          </Button>
        </Row>
        <Row>
          <Gallery
            imagesRef={imagesList}
            showGallery={showGallery}
            setShowGallery={setShowGallery}
            showImageByID={selectedImageID}
            setSelectedImageID={setSelectedImageID} />
        </Row>
      </Row>
    </Container>);
}
export default Content;
