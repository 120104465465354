import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from 'Footer';
import Header from 'Header';
import Content from 'Content';
import NotFound from 'NotFound';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Container className='mx-0 px-0 w-100' fluid>
        <Header />
        <Routes>
          <Route index element={<Content />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Container>
    </BrowserRouter>
  );
}

export default App;
